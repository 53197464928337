<template>
  <b-form @submit.prevent="save">
    <b-tabs>
      <b-tab v-for='lang in ["en", "ru"]' :key="lang" :title="lang">
        <br/>
        <b-form-group :label="$t('Name')">
          <b-textarea v-model="name[lang]" rows="3"></b-textarea>
        </b-form-group>
        <b-form-group :label="$t('Description')">
          <b-textarea v-model="description[lang]" rows="5"></b-textarea>
        </b-form-group>
      </b-tab>
    </b-tabs>
    <b-form-row class="actions">
      <b-button variant="primary" @click="save">{{$t('Ok')}}</b-button>
      <b-button variant="outline-secondary" @click="cancel">{{$t('Cancel')}}</b-button>
    </b-form-row>
  </b-form>
</template>

<script>
  import {get_type} from "../shared";

  export default {
    name: "EditQuestion",
    props: {
      question: {
        type: [String, Number],
      }
    },
    mounted() {
      this.name = {...this.obj.name}
      this.description = {...this.obj.description}
    },
    data() {
      return {
        name: {ru: '', en: ''},
        description: {ru: '', en: ''}
      }
    },
    computed: {
      obj() {
        let key = get_type(this.question) == 'String' ? 'uuid' : 'pk'
        return this.$store.getters.question.find(q => q[key] == this.question)
      },
    },
    methods: {
      save() {
        this.$store.dispatch(
          'update_item', {
            type: 'question',
            id: this.question,
            data: {name: this.name, description: this.description}
          }
        ).then(() => {
          this.$emit('ok')
        })
      },
      cancel() {
        this.$emit('cancel')
      }
    }
  }
</script>

<style scoped>
  .actions {
    margin: 0;
  }
</style>
