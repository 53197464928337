<template>
  <b-list-group>
    <draggable v-model="questions" handle=".drag-icon">
      <b-list-group-item v-for="q in questions" :key="q.uuid" class="d-flex justify-content-between align-items-center">
        <span>
        <fragment v-if="q.order!==undefined">{{q.order}}:</fragment>
        {{q._t_name}}
        <b-badge v-if='q.order===undefined' variant="primary"> new</b-badge>
          <!--        <span class="text-muted">{{translate_question(q.description)}}</span>-->
        </span>
        <b-button-group>
          <b-button variant="secondary-primary" class="drag-icon">
            <b-icon-arrows-expand/>
          </b-button>
          <b-button variant="outline-warning" @click="edit_question(q)">
            <b-icon-pen/>
          </b-button>
          <b-button variant="outline-danger" @click="remove_question(q)">
            <b-icon-trash/>
          </b-button>
        </b-button-group>
      </b-list-group-item>
      <b-list-group-item class="add-button">

        <b-button block @click="show_add_modal()">
          <b-icon-plus/>
        </b-button>
      </b-list-group-item>
    </draggable>
    <b-modal v-model="question_modal" hide-header hide-footer ref="modal">
      <edit-question v-if='question_modal' :question="edit_question_id"
                     @ok="close_modal"
                     @cancel="close_modal"/>

    </b-modal>
    <b-modal v-model="add_modal" hide-header hide-footer @ok="add_to_questions()" @cancel="to_add=[]">
      <b-tabs>
        <b-tab title="Existed" lazy>
          <br/>
          <b-input-group>
            <b-input v-model="filter_str"/>
            <b-input-group-append v-if="filter_str.length">
              <b-button variant="outline-primary" class="border-0" @click="filter_str = ''">
                <b-icon-x-circle/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-list-group class="scrollable">
            <b-list-group-item v-for="q in unused_questions" :key="q.uuid" class="add-button">
              <b-checkbox v-model="to_add" :value="q" button button-variant="outline-primary" class="w-100">
                {{q.t_name}}
              </b-checkbox>
            </b-list-group-item>
          </b-list-group>
          <br/>
          <b-form-row>
            <b-button variant="primary" @click="add_to_questions()">Ok</b-button>
            <b-button variant="outline-secondary" @click='close_add_modal'>Cancel</b-button>
          </b-form-row>
        </b-tab>
        <b-tab title="New" lazy>
          <br/>
          <b-form>
            <b-form-group :label="$t('name_with_lang', {lang: 'en'})">
              <b-form-textarea v-model="name_en" rows="3"/>
            </b-form-group>
            <b-form-group :label="$t('name_with_lang', {lang: 'ru'})">
              <b-form-textarea v-model="name_ru" rows="3"/>
            </b-form-group>
            <b-form-group :label="$t('desc_with_lang', {lang:'en'})">
              <b-form-textarea v-model="description_en" rows="3"/>
            </b-form-group>
            <b-form-group :label="$t('desc_with_lang', {lang:'en'})">
              <b-form-textarea v-model="description_ru" rows="3"/>
            </b-form-group>
            <b-form-group label="">
              <b-button @click="add_question()" variant="primary">Add question</b-button>
              <b-button @click="close_add_modal()" variant="outline-secondary">Cancel</b-button>
            </b-form-group>
          </b-form>
        </b-tab>
      </b-tabs>
    </b-modal>
  </b-list-group>
</template>

<script>
  import {orderBy, merge, flatten} from 'lodash-es'
  import draggable from 'vuedraggable'
  import EditQuestion from "./EditQuestion";
  import {Fragment} from 'vue-fragment'


  export default {
    name: "QuestionsForCategory",
    components: {EditQuestion, draggable, Fragment},
    props: {
      category_id: {
        type: Number,
        required: true
      }
    },
    mounted() {
      this.questions = this.get_questions()
    },
    data() {
      return {
        filter_str: '',
        questions: [],
        edit_question_id: null,
        add_modal: false,
        to_add: [],
        question_to_add: {
          name: {ru: "", en: ""},
          description: {ru: '', en: ''}
        }
      }
    },
    methods: {
      close_add_modal() {
        this.to_add = []
        this.add_modal = false
        this.reset_question_to_add()
      },
      add_question() {
        this.$store.dispatch(
          "add_question", [this.category.uuid, this.question_to_add]
        ).then(
          () => {
            this.add_modal = false
            this.reset_question_to_add()
            this.$store.dispatch('load_data', ['question']).then(() =>
              this.questions = this.get_questions()
            )
          }
        )
      },
      reset_question_to_add() {
        for (const key of ['name', 'description']) {
          for (const lang of ['ru', 'en']) {
            this.question_to_add[key][lang] = ''
          }
        }
      },
      show_add_modal() {
        this.add_modal = true
      },
      close_modal() {
        this.question_modal = false
        this.questions = this.get_questions()
      },
      translate_question(value) {
        return value[this.$i18n.locale] || value['en'] || null
      },
      get_questions() {
        const questions = this.$store.getters.question
        const q_uuid = orderBy(this.category.questions || [], ['order'], ['asc'])
        .map(q => merge(questions.find(q2 => q.uuid == q2.uuid), {order: q.order}))
        return q_uuid
      },
      edit_question(q) {
        this.question_modal = q.uuid
      },
      add_to_questions() {
        this.questions = this.questions.concat(this.to_add)
        this.to_add = []
        this.add_modal = false
      },
      remove_question(q) {
        this.$bvModal.msgBoxConfirm(this.$t('Are you really want delete question from category?')).then(
          resp => {
            if (resp) {
              this.questions = this.questions.filter(cq => cq.uuid !== q.uuid)
            }
          }
        )
        
      }
    },
    computed: {
      unused_questions() {
        let used_questions = this.questions.map(q => q.uuid)
        const question_str =
          (q) => flatten(Object.values(q.name), Object.values(q.description)).join(' ').toLowerCase()
        const filter_str = this.filter_str.toLowerCase()
        return this.$store.getters.question.filter(
          q => used_questions.indexOf(q.uuid) === -1 && !!this.filter_str.length ? question_str(q).indexOf(filter_str) !== -1 : true
        ).map(
          q => merge({}, q, {
            t_name: this.translate_question(q.name),
            t_description: this.translate_question(q.description)
          })
        )
      },
      question_modal: {
        get() {
          return this.edit_question_id !== null
        },
        set(to) {
          if (to === false || to === true) {
            this.edit_question_id = null
          } else {
            this.edit_question_id = to
          }
        }
      },
      category() {
        return this.$store.getters.category.find(
          c => c.pk == this.category_id
        )
      },
      ...['name', 'description'].reduce(
        (r, key) => {
          return merge(r, ['ru', 'en'].reduce((r2, l) => ({
            ...r2, [`${key}_${l}`]: {
              get() {
                return this.question_to_add[key][l]
              },
              set(to) {
                this.question_to_add[key][l] = to
              }
            }
          }), {}))
        }, {}
      )
    }
  }
</script>

<style lang="scss">
  button.btn.drag-icon {
    cursor: grab;
  }

  .scrollable {
    max-height: 70vh;
    overflow: auto;
  }

  .add-button {
    padding: 0;

    .w-100 {

      label.btn {
        width: 100% !important;
        text-align: justify;
      }

    }
  }


</style>
