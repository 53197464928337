<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>
          {{ $t("Categories") }}
          <b-link :to="{ name: 'Options' }">
            <b-icon-arrow-return-left />
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items" />
      </b-col>
    </b-row>
    <b-overlay :show="loading">
      <b-row>
        <b-col>
          <b-button
            @click="show_create_modal()"
            variant="outline-primary"
            class="float-right"
            >+</b-button
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table :items="category_list" :fields="fields">
            <template #cell(question_count)="item">
              {{ item.item.questions.length }}
            </template>
            <template #cell(actions)="item">
              <b-button-group>
                <b-button
                  variant="warning"
                  @click="show(item)"
                  size="lg"
                  v-b-tooltip="{ title: $t('Edit') }"
                >
                  <b-icon-pen />
                </b-button>
                <b-button
                  :variant="
                    !item.item.deleted ? 'outline-danger' : 'outline-success'
                  "
                  size="lg"
                  v-b-tooltip="{
                    title: item.item.deleted
                      ? $t('Activate')
                      : $t('Deactivate'),
                  }"
                  @click="toggle_category(item)"
                >
                  <b-icon-eye-slash v-if="!item.item.deleted" />
                  <b-icon-eye v-else />
                </b-button>
                <b-button
                  size="lg"
                  variant="danger"
                  v-b-tooltip="$t('Delete')"
                  @click="delete_category(item)"
                  v-if="is_superuser && item.item.deleted"
                >
                  <b-icon-trash />
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-overlay>
    <b-modal v-model="show_create" hide-header hide-footer>
      <b-form-group label="Name">
        <b-input v-model="send_data.name" />
      </b-form-group>
      <b-form-group label="Short name">
        <b-input v-model="send_data.short_name" />
      </b-form-group>
      <b-form-group label="Description">
        <b-textarea v-model="send_data.description" />
      </b-form-group>
      <b-form-row>
        <b-button @click="create_ok()">Ok</b-button>
      </b-form-row>
    </b-modal>
    <b-modal
      v-model="show_modal"
      lazy
      @close="close"
      @ok="save_questions"
      size="xl"
    >
      <questions-for-category
        v-if="show_category !== null"
        ref="q_list"
        :category_id="show_category"
      />
    </b-modal>
  </b-container>
</template>

<script>
import QuestionsForCategory from "../components/QuestionsForCategory";

export default {
  name: "CategoryList",
  components: { QuestionsForCategory },
  mounted() {
    this.load_data();
  },
  data() {
    return {
      loading: false,
      show_modal: false,
      show_category: null,
      show_create: false,
      send_data: {
        name: "",
        short_name: "",
        description: "",
        questions: [],
      },
    };
  },
  computed: {
    is_superuser() {
      let ret = false;
      if (this.$store.getters.has_auth) {
        ret = this.$store.getters.user_info.is_superuser;
      }
      return ret;
    },
    br_items() {
      return [
        { text: this.$t("Home"), to: { name: "Home" } },
        { text: this.$t("Settings"), to: { name: "Options" } },
        { text: this.$t("Categories"), active: true },
      ];
    },
    category_list() {
      return this.$store.getters.category.filter((c) => !c.mark_as_deleted);
    },
    fields() {
      return [
        { key: "name", label: this.$t("Name") },
        { key: "description", label: this.$t("Description") },
        { key: "question_count", label: this.$t("Questions Count") },
        { key: "actions", label: this.$t("Actions") },
      ];
    },
  },
  methods: {
    delete_category(item) {
      const obj = item.item;
      this.$bvModal
        .msgBoxConfirm(this.$t("You really want delete this category?"))
        .then((ansv) => {
          if (ansv) {
            this.$store.dispatch("update_item", {
              type: "category",
              id: obj.uuid,
              data: { mark_as_deleted: true },
            });
          }
        });
    },
    toggle_category(item) {
      const obj = item.item;
      const msg_deactivate = `You really want deactivate this category (${obj.name})?`;
      const msg_activate = `You really want activate this category (${obj.name})?`;
      const msg = obj.deleted ? this.$t(msg_activate) : this.$t(msg_deactivate);
      this.$bvModal.msgBoxConfirm(msg).then((val) => {
        if (val) {
          const deleted = obj.deleted ? null : new Date();
          this.$store.dispatch("update_item", {
            type: "category",
            id: obj.uuid,
            data: { deleted },
          });
        }
      });
    },
    create_ok() {
      this.$store
        .dispatch("add_item", { type: "category", data: this.send_data })
        .then((ret) => {
          this.show_create = false;
          this.show({ item: { pk: ret.pk } });
        });
    },
    show_create_modal() {
      this.show_create = true;
    },
    save_questions() {
      const new_questions = this.$refs.q_list.questions.map((q, i) => ({
        order: i + 1,
        uuid: q.uuid,
        pk: q.pk,
      }));
      this.$store.dispatch("update_item", {
        type: "category",
        id: this.$refs.q_list.category.uuid,
        data: { questions: new_questions },
      });
      this.close();
    },
    show(item) {
      this.show_modal = true;
      this.show_category = item.item.pk;
    },
    close() {
      this.show_modal = false;
      this.show_category = null;
    },
    load_data() {
      this.loading = true;
      this.$store.dispatch("load_data", ["category", "question"]).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
</style>
